import axios, { AxiosInstance } from 'axios';
import router from '@/router';
import { getToken } from '../functions/auth';

export default class ApiService {
  protected axios: AxiosInstance;

  constructor() {
    this.axios = axios.create({ baseURL: process.env.VUE_APP_API_URL });
    this.setConfigHeaders();
  }

  setConfigHeaders() {
    this.axios.interceptors.request.use(async (config) => {
      const configuration = config;
      const token = getToken();
      if (token) {
        configuration.headers = {
          ...config.headers,
          Authorization: `Bearer ${token}`,
        };
      }
      return configuration;
    });

    this.axios.interceptors.response.use(
      (response) => {
        return response;
      },
      (error) => {
        if (error.response.status === 401) {
          window.localStorage.clear();
          router.push('/login');
        }
        return Promise.reject(error);
      }
    );
  }
}
