import { getModule } from 'vuex-module-decorators';
import { Store } from 'vuex';
import NotificationStore from '@/store/modules/notification';

export default function displayMessage(
  message: string,
  isError: boolean,
  store: Store<NotificationStore>,
  statusCode = 0
): void {
  const timestamp = Date.now();
  const notificationStore = getModule(NotificationStore, store);
  notificationStore.showNotificationSnackbar({
    isError,
    message,
    statusCode,
    clearable: true,
    timestamp,
  });
}
